@use '../shared' as *;

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  
  h2 {
    padding: 0;
    margin: 0 0 12px 0;
    font-size: $type-default;
    color: $color-white;
    text-transform: uppercase;
  }
}

.timeframe-links {
  button {
    color: $color-white-half;
    background: $color-gray-darkest;
    border: none;
    outline: none;
    border-radius: $radius-slight;
    padding: 6px 12px;
    cursor: pointer;
    font-family: $font-default;
    text-transform: uppercase;
    font-size: $type-default;
    font-weight: $regular;
    border: 1px solid transparent;
    
    @media (min-width: $break-md) {
      padding: 6px 12px;
    }


    &.selected {
      border: 1px solid $color-brand-default;
      color: $color-white;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}
