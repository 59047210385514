@use '../shared' as *;

.module {

  &-hide {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 12px;
    right: 12px;
    
    button {
      font-size: $type-tiny;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      color: $color-white;
      background: transparent;
      cursor: pointer;
    }
  }


}
