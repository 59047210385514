@use '../shared' as *;


.price {
  h2 {
    .number {
      color: $color-white;
      font-size: $type-huge;

      small {
        color: $color-white;
        margin-right: 0.1em;
      }

      .zeros {
        color: $color-white;
        vertical-align: sub;
        font-size: 0.6em;
      }
    }
  }
}
