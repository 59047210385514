@use '../shared' as *;

.meter {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 24px 0;

  label {
    font-size: $type-tiny;
    font-weight: $regular;
    margin: 12px 0;
  }

  &-face {
    padding: 12px;
    border-radius: $radius-slight;
    background: $color-gray-darkest;
    display: inline-flex;
    align-items: center;
    font-size: $type-sm;

    i {
      font-size: $type-xxl;
      margin: 0;
    }

    &-good {
      @extend .meter-face;
      // border: 1px solid $color-good-glow;
      i {
        color: $color-good;
      }
    }
    &-neutral {
      @extend .meter-face;
      // border: 1px solid $color-neutral-glow;
      i {
        color: $color-neutral;
      }
    }
    &-bad {
      @extend .meter-face;
      // border: 1px solid $color-bad-glow;
      i {
        color: $color-bad;
      }
    }
  }

  div {
    height: 6px;
    font-size: $type-tiny;

    b {
      position: relative;
      font-size: $type-tiny;
      top: 12px;
      font-weight: $default;
      justify-content: center;
      align-items: center;
      display: flex;
      color: $color-white;
      text-align: center;
      flex-direction: column;

      // i,
      // img {
      //   display: none;
      // }
    }
    strong {
      position: relative;
      font-size: $type-tiny;
      top: 0;
      font-weight: $default;
      justify-content: center;
      align-items: center;
      display: flex;
      color: $color-white;
      text-align: center;
      flex-direction: column;

      i {
        font-size: $type-icon;
        align-self: center;
      }
    }
  }

  &-good {
    border-radius: $radius-slight;
    background: $color-good;
    margin: 0 3px 0 0;
    box-shadow: 0 0 36px 3px $color-good-glow;
    transition: width 0.3s ease-in;

    i {
      color: $color-good;
    }
  }

  &-neutral {
    border-radius: $radius-slight;
    background: $color-neutral;
    margin: 0 6px;
    box-shadow: 0 0 36px 3px $color-neutral-glow;
    transition: width 0.3s ease-in;

    i {
      color: $color-neutral;
    }
  }
  &-bad {
    border-radius: $radius-slight;
    background: $color-bad;
    margin: 0 0 0 3px;
    box-shadow: 0 0 36px 3px $color-bad-glow;
    transition: width 0.3s ease-in;

    i {
      color: $color-bad;
    }
  }

  &-versus {
    font-size: $type-teeny;

    &-up {
      margin: 0 0 12px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-note {
        line-height: $type-default;
        strong {
          color: $color-mid;
          font-weight: $bold;
          margin: 0 0 0 3px;
          font-size: $type-tiny;
        }
      }
      &-bar {
        height: 6px;
        margin: 3px 0;
        display: flex;
        border-radius: $radius-slight;
        background: $color-mid;
      }
    }
    &-down {
      margin: 0 0 12px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-note {
        line-height: $type-default;
        strong {
          color: $color-white-half;
          font-weight: $bold;
          margin: 0 0 0 3px;
          font-size: $type-tiny;
        }
      }
      &-bar {
        height: 6px;
        margin: 3px 0;
        display: flex;
        border-radius: $radius-slight;
        background: linear-gradient(to left, $color-white-slight 0%, transparent 100%);
      }

      &-max {
        margin: 0 0 12px 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        span {
          height: 6px;
          margin: 3px 0;
          display: flex;
          border-radius: $radius-slight;
          background: linear-gradient(to left, $color-white-light 0%, $color-white-slight 36%, transparent 100%);
        }
      }
    }
  }
}
