@use '../shared' as *;


.offer-feature {
  padding: 0 0 36px 0;

  header {
    a {
      background: $color-white-note;
      padding: 24px 0;
      width: 100%;
      border: 1px solid transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      color: inherit;
      text-decoration: none;
      justify-content: center;
      align-items: center;

      i {
        font-size: $type-huge;
        transition: $transition-scale;
      }

      h1,
      h2 {
        text-transform: uppercase;
        transition: $transition-scale;
        text-align: center;
      }

      h1 {
        margin-top: 12px;
        font-weight: $bold;
        font-size: $type-lg;
      }

      h2 {
        font-size: 2.4em;
        font-size: $type-h2;
        margin: 0;
        line-height: 1;
      }

      h3 {
        text-align: center;
        margin: 0;
        font-size: $type-h1;
        font-weight: $regular;
        color: $color-white-default;
        transition: $transition-all;
      }

      h4 {
        text-align: center;
        margin: 12px 0 0 0;
        padding: 0 6px;
        font-weight: $regular;
        opacity: 1;
        transform: rotate(-6deg) scale(1.7);
        transition: $transition-all;
        font-size: $type-xs;
        opacity: 0;
      }

      div {
        margin: 12px 0;
        display: flex;
        flex-direction: column;
        text-align: center;

        span {
          padding: 6px 0;
        }
      }

      p {
        text-align: center;
        margin: 12px 0;
        font-size: $type-xxs;
        font-weight: $default;
        color: $color-white-default;
      }

      &:hover {
        border: 1px solid $color-brand-slight;

        i {
          transform: scale(1.3);
          transition: $transition-scale;
        }

        @media (min-width: $break-md) {
          h3 {
            transform: rotate(-6deg) scale(1.7);
            transition: $transition-all;
          }

          h4 {
            text-align: center;
            margin: 12px 0 0 0;
            padding: 0 6px;
            font-weight: $regular;
            opacity: 1;
            transform: rotate(-6deg) scale(1.3);
            transition: $transition-all;
            font-size: $type-xs;
          }
        }
      }
    }
  }
  &-promotion {
    @extend .offer-feature;

    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    button {
      background: transparent;
      font-size: $type-default;
      outline: none;
      border: none;
      font-family: $font-default;
      cursor: pointer;
      text-decoration: underline;
      font-family: $font-default;
      font-weight: $regular;
      color: $color-brand;
      text-transform: uppercase;
      align-self: flex-end;
      padding: 6px 12px;
      transition: $transition-scale;
      transform: scale(1);

      &:hover {
        background: $color-brand;
        color: $color-gray-darkest;
        text-decoration: none;
        transform: scale(1.1);
        transition: $transition-scale;
      }
    }

    header {
      a {
        background: transparent;
        padding: 24px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        color: inherit;
        text-decoration: none;
        justify-content: center;
        align-items: center;

        i {
          font-size: $type-huge;
          transition: $transition-scale;
        }

        p {
          padding: 6px 0 0 0;
          margin: 0;
        }

        h1 {
          font-size: $type-h2;
          font-weight: $regular;
        }

        &:hover {
          border: none;
          
          i {
            transform: scale(1.5);
            transition: $transition-scale;
          }
        }
      }
    }


  }
}