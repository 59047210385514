@use '../shared' as *;

// gauge

.gauge {
  &-container {
    padding: 6px 0;
    border-radius: $radius-slight;
    margin: 0 0 12px 0;
  }

  &-bar {
    height: 6px;
    box-sizing: content-box;
    position: relative;
    width: auto;
    display: flex;
    right: 0;
    left: 0;
    top: 12px;
    transition: $transition-transform;
    background: linear-gradient(
      to right,
      $color-red 0%,
      $color-red 12.5%,
      $color-pink 25%,
      $color-orange 37.5%,
      $color-yellow 50%,
      $color-yellow 62.5%,
      $color-green 75%,
      $color-green 87.5%,
      $color-green 100%
    );
    border-radius: $radius-slight;

    &-positive {
      @extend .gauge-bar;
      transform-origin: left;
      background: linear-gradient(to right, $color-green-slight 0%, $color-green 100%);
      border-radius: 0;
    }
    &-negative {
      @extend .gauge-bar;
      transform-origin: left;
      background: linear-gradient(to right, $color-pink-slight 0%, $color-pink 100%);
      border-radius: 0;
    }
    &-neutral {
      @extend .gauge-bar;
      transform-origin: left;
      background: linear-gradient(to right, $color-orange-slight 0%, $color-orange 100%);
      border-radius: 0;
    }
  }

  &-current {
    padding: 0;
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    color: $color-white;
    margin: 0;
    transition: $transition-left;
    align-items: center;
    text-align: center;
  }

  &-icon {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 19px;
    left: 0;
    right: 0;
    color: $color-white;
    padding: 0;
    transition: $transition-left;
    align-items: center;
    text-align: center;
  }

  &-marker {
    position: relative;
    bottom: -32px;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    font-weight: $regular;

    i {
      color: $color-white;
      font-size: $type-default;
      margin: 0;
    }

    img {
      height: 36px;
    }

    &-positive {
      @extend .gauge-marker;
      // bottom: -2px; for icons
      bottom: 6px;
      right: 19px;
      i {
        color: $color-white;
        font-size: $type-sm;
        margin: 0;
      }

      img {
        height: 36px;
      }
    }
    &-negative {
      @extend .gauge-marker;
      // bottom: -2px; for icons
      bottom: 7px;
      left: 12px;

      i {
        color: $color-white;
        font-size: $type-sm;
        margin: 0;
      }

      img {
        height: 36px;
      }
    }

    &-number {
      position: absolute;
      top: 3px;
      color: $color-white;
      font-weight: $regular;
      font-size: $type-tiny;
    }
  }
}
