@use '../shared' as *;

.recharts {
  &-wrapper {
    max-height: 84px !important;
  }

  &-responsive-container {
    padding: 0;
  }

  &-tooltip-wrapper {
    z-index: 3 !important;
    box-shadow: -3px -3px 6px $color-gray-darkest;
  }
}

.chart-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  margin: 0 24px 12px 24px;


  &-responsive {
    box-sizing: content-box;
    align-items: flex-start;
    box-sizing: content-box;
    background: $color-white-hint;
    @media (min-width: $break-md) {
      margin: 0;
      // @media (min-width: 1280px) and (-webkit-min-device-pixel-ratio: 2),
      // (min-width: 1280px) and (min-resolution: 192dpi) {
      background: repeating-linear-gradient(
        0deg,
        $color-gray-light,
        $color-gray-light 1.5px,
        transparent 1.5px,
        transparent 3px
      );
      box-shadow: 0 0 36px inset $color-gray-light;
    }
    border-radius: $radius-default;
    padding: 24px !important;
    min-height: 84px;
    align-content: flex-end;
  }

  .chart-area-price {
    margin-bottom: 1em;
  }

  .custom-tooltip {
    background-color: $color-gray-darkest;
    color: #fff;
    border-radius: $radius-default;
    box-shadow: 0 2px 4px $color-gray-light;

    .color-box {
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-right: 6px;
    }

    &.micro-tooltip {
      padding: 3px 6px;
      margin-top: -18%;
      font-size: 0.6rem;
    }

    &.normal-tooltip {
      // margin-top: -25px;
      padding: 6px 12px;
    }
  }


}
