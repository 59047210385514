@use '../shared' as *;


.details {

    display: flex;
    flex-direction: column;
    
    p {
        font-size: $type-xxs;
        color: $color-white;
        padding: 12px 0 3px 0;
        margin: 0;
        font-weight: $default;
    }

}
