@use '../shared' as *;
@use 'general' as *;

.sponsor {
  &-container {
    @include general-container;
    z-index: $z-module;
    transition: $transition-all;
    border: 6px solid transparent;
    color: $color-white;
    flex-direction: column;
    margin: 6px 0;
    padding: 0 !important;
    border-radius: $radius-default;
    transition: $transition-background;
    background: $color-gray-half;
    border: 1px solid transparent;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 50% 50%;

    // @media (min-width: $break-md) {
    //   margin: 12px;
    //   padding: 0;
    //   background: $color-gray-light;
    // }
  }

  &-handle {
    
  }

  &-data {
    padding: 12px;
    margin: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    box-sizing: border-box;

    a {
      color: $color-white;
      font-size: $type-lg;
      text-decoration: none;
      font-weight: $default;
      margin: 0 0 12px 0;
      i {
        color: $color-white;
        font-size: $type-tiny;
        padding: 0 0 0 6px;
      }
    }

    b {
      font-size: $type-lg;
      color: $color-white;
      font-weight: $default;

      small {
        font-size: $type-teeny;
        display: flex;
      }
    }

    img {
      padding: 0 !important;
      margin: 0 !important;
      width: 100%;
      display: flex;
    }
  }
}
