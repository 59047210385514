@use '../shared' as *;

.vector {

  small {
    color: $color-white-default;
    font-size: $type-default;
    text-transform: uppercase;
    margin: 24px 0 12px 0;
    display: flex;
  }

  &-container {
    padding: 36px 0 6px 0;
    border-radius: $radius-slight;
    margin: 0;
    transform-origin: left;
    display: flex;
    transition: transform .3s ease-in;
  }

  &-bar {
    height: 6px;
    box-sizing: content-box;
    position: relative;
    width: auto;
    display: flex;
    right: 0;
    left: 0;
    top: 0;

    &-positive {
      position: relative;
      width: 100%;
      height: 24px;
      background: linear-gradient(to right, transparent 12%, $color-good 84%);

      h2 {

        position: relative;
        top: -48px;
        text-transform: uppercase;
        align-items: flex-end;
        display: flex;

        img {
          margin: 0 6px 0 0;
          height: 36px;
        }

      }

      small {
        position: relative;
        bottom: 18px;
        text-transform: uppercase;
      }

      span {
        font-size: $type-lg;
        position: absolute;
        top: -2px;
        color: $color-white;
        &:after {
         // content: '%';
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -16px;
        border-width: 16px 0 16px 16px;
        border-style: solid;
        border-color: transparent transparent transparent $color-good;
      }
    }

    &-negative {
      position: relative;
      width: 100%;
      height: 24px;
      background: linear-gradient(to right, transparent 12%, $color-bad 84%);
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -15px;
        margin-top: -16px;
        border-width: 16px 0 16px 16px;
        border-style: solid;
        border-color: transparent transparent transparent $color-bad;
      }

      h2 {
        position: relative;
        top: -48px;
        text-transform: uppercase;
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;

        img {
          margin: 0 6px 0 0;
          height: 36px;
          align-self: flex-end;
        }
      }

      small {
        position: relative;
        bottom: 18px;
        text-transform: uppercase;
      }

      span {
        font-size: $type-lg;
        color: $color-white;
        position: absolute;
        top: -2px;
        &:after {
         // content: '%';
        }
      }
    }
    &-neutral {
      position: relative;
      width: 100%;
      height: 24px;
      background: linear-gradient(to right, transparent 12%, $color-neutral 84%);
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -16px;
        margin-top: -16px;
        border-width: 16px 0 16px 16px;
        border-style: solid;
        border-color: transparent transparent transparent $color-neutral;
      }
      
      h2 {
        position: relative;
        top: -40px;
        text-transform: uppercase;
      }

      small {
        position: relative;
        bottom: 18px;
        text-transform: uppercase;
      }

      span {
        font-size: $type-lg;
        color: $color-white;
        position: absolute;
        top: -2px;
        &:after {
         // content: '%';
        }
      }
    }
  }

  &-current {
    padding: 0;
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    color: $color-white;
    margin: 0;
    transition: $transition-left;
    align-items: center;
    text-align: center;
  }

  &-icon {
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 19px;
    left: 0;
    right: 0;
    color: $color-white;
    padding: 0;
    transition: $transition-left;
    align-items: center;
    text-align: center;
  }

  &-marker {
    position: relative;
    bottom: -32px;
    width: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    font-weight: $regular;

    i {
      color: $color-white;
      font-size: $type-default;
      margin: 0;
    }

    &-positive {
      @extend .vector-marker;
      bottom: -2px;
      i {
        color: $color-white;
        font-size: $type-sm;
        margin: 0;
      }
    }
    &-negative {
      @extend .vector-marker;
      bottom: -2px;
      i {
        color: $color-white;
        font-size: $type-sm;
        margin: 0;
      }
    }

    &-number {
      position: absolute;
      top: 3px;
      color: $color-white;
      font-weight: $regular;
      font-size: $type-tiny;
    }
  }
}
