@use '../shared' as *;

.rollup {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .number-change {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    justify-content: center;
  }

  h2 {
    text-align: center;
    font-size: $type-md;
    color: $color-white;
    padding: 0;
    margin: 0;
    border-radius: $radius-default;
    transition: $transition-scale;
    background: $color-white-hint;
  }


  &-micro {

    font-size: $type-sm;

    small {
      font-size: $type-teeny;
    }

 
  }

  &-macro {
    .rollup-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .number {
        display: flex;
        flex-direction: row;
        font-size: $type-md;
        justify-content: center;
        color: #fff;
        z-index: 2;
        line-height: 1;

        .number-change {
          font-size: inherit;
        }
      }

      .label {
        margin-top: 15px;
        font-size: 9px;
        font-weight: 100;
        color: #fff;
        text-transform: uppercase;
        z-index: 2;
      }
    }
  }
}
