@use '../shared' as *;

.loading {
  // span {
  //   color: $color-pink;
  //   font-size: $type-tiny;
  //   margin: 0 3px 0 0;
  //   opacity: 0; // Start hidden
  //   animation: fadeIn 0.5s forwards;
  //   animation-delay: 5s; 
  // }
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  min-height: 100%; // This ensures full viewport height

  span{
    margin: 0 0 0 3px;
    font-size: $type-teeny;
    color: $color-white-light;
  }

  i {
    animation:
      spinPulse 1s linear infinite;
      // spin 1s linear infinite,
      // colorChange 5s linear 1;
    // animation-fill-mode: forwards;
    // color: $color-brand;
    font-size: $type-sm;
    color: $color-white-default;
    // align-self: center;
  }
}

@keyframes spinPulse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes colorChange {
  0% {
    color: $color-white;
  }
  96% {
    color: $color-white;
  }
  100% {
    color: $color-pink;
  }
}

.highlight-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.highlight {
  color: $color-white;
  font-size: $type-sm;
  padding: 3px 6px;
  margin: 6px 0;
  background: $color-brand-slight;
  border-radius: $radius-slight;
  display: inline-block;
  cursor: pointer;
  outline: none;
  width: auto;
  text-decoration: none;
}

.lowlight {
  color: $color-white-light;
  font-size: $type-default;
  cursor: pointer;
  display: inline-block;
  width: auto;
  text-decoration: none;
}

.pulse {
  animation: pulse;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.fade-in {
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
}

.fade-in-slow {
  animation: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
}

.fade-delay-long {
  animation: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-delay: 1s;
  opacity: 0;
}
.fade-delay-3s {
  animation: fadeIn;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-delay: 3s;
  opacity: 0;
}

.fade-delay-short {
  animation: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  animation-delay: 0.3s;
  opacity: 0;
}

.pulse-turbo {
  animation: pulse;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 3;
}

.pulse-slow {
  animation: pulseSlow;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

.slide-down {
  animation: slideDown;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
}

@keyframes slideDown {
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.zoom-in {
  animation: zoomIn;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
}

@keyframes zoomIn {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseSlow {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.36;
  }

  100% {
    opacity: 1;
  }
}

@keyframes borderPulse {
  0% {
    border: 1px solid $color-crosshairs-note;
  }

  50% {
    border: 1px solid $color-crosshairs;
  }

  100% {
    border: 1px solid $color-crosshairs-note;
  }
}

@keyframes elastic {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

.grow-right {
  animation: growRight;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-direction: normal;
  animation-play-state: running;
  animation-iteration-count: 1;
  transform-origin: left;
  animation-delay: 0.3s;
  transform: scaleX(0);
  opacity: 0;
}

@keyframes growRight {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  24% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.spin {
  animation: spin 1s infinite linear;
  transform-origin: center center;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinHalf {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

$duration: 24s;
$durationFast: 12s;

@keyframes tickerReverse {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wave {
  0% {
    transform: translate(-10px, 0);
  }
  100% {
    transform: translate(10px, 0);
  }
}
