@use '../shared' as *;


.chart-posnegbar {



  .recharts-tooltip-wrapper {
    z-index: 3 !important;
  }

  .recharts-surface {
    .recharts-bar-rectangles {
      opacity: 0.3;
      transition: $transition-opacity;
    }
    &:hover {
      .recharts-bar-rectangles {
        transition: $transition-opacity;
        opacity: 0.72;
      }

      .recharts-layer.recharts-line path {
        stroke: $color-brand;
        stroke-width: 3px;

      }

    }
  }

  .custom-tooltip {
    background-color: black;
    color: white;
    padding: 0px 7px;
    border-radius: 5px;
    box-shadow: 0 2px 4px $color-gray-darkest;

    label {
    
      .date {
        margin-left: 10px;
      }

      .price {
        margin-left: 10px;
        color: $color-brand;
      }

      .positive-value {
        color: $color-mid;
      }

      .negative-value {
        color: $color-pink;
      }
    }
  }
}
