@use '../shared' as *;


.accuracy {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  margin: 12px 0;
  z-index: $z-base;


  &-toggle {
    display: flex;
    justify-content: flex-end;

    i {
      font-size: $type-default;
      padding: 0 1px;
      color: $color-white-light;
      vertical-align: middle;

      &:hover {
        color: $color-brand;
      }
    }

    .toggle-selected {
      i {
        color: $color-brand;
        vertical-align: middle;
      }
    }

    .toggle-small {
      i {
        font-size: $type-mono;
        padding: 0 1px;
        vertical-align: middle;
      }
      &-selected {
        i {
          color: $color-brand;
          font-size: $type-mono;
          padding: 0 1px;
          vertical-align: middle;
        }
      }
    }

    button {
      font-size: $type-md;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      color: $color-white;
      background: transparent;

      &:hover {
        i {
          color: $color-brand;
        }
      }
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    font-size: $type-xs;
  }

  &-quadrants {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 6px;
    width: 120px;
    height: 120px;
    flex-direction: column;
    margin: 0 0 12px 0;
    justify-content: center;
    transition: $transition-all;
  }

  &-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    flex-direction: row;
    gap: 6px;
    width: 120px;
    height: 60px;
    margin: 12px 0;
    justify-content: center;
    flex-direction: column;
    transition: $transition-all;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-white-note;
    background: $color-black-hint;
    position: relative;
    cursor: pointer;
    width: 60px;
    height: 60px;

    label {
      color: $color-white-default;
      position: absolute;
      top: 6px;
      left: 6px;
      font-size: $type-note;
    }
    b {
      color: $color-white-default;
      position: absolute;
      bottom: 6px;
      right: 6px;
      font-size: $type-md;
      font-weight: $default;
    }

    .disabled {
      color: $color-white-half;
    }
  }

  &-average {
    position: absolute;
    top: calc(50%);
    left: 50%;
    bottom: auto;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 36px;
    background: $color-gray-darker;
    font-size: $type-lg;
    font-weight: $default;
    z-index: $z-default;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    pointer-events: none;

    &-alt {
      display: flex;
      justify-content: center;
      font-size: $type-default;
      font-weight: $default;
      text-transform: uppercase;
      flex-direction: column;
      text-align: center;

      div {
        display: flex;
        justify-content: center;
        text-align: center;
      }

      span {
        margin: 0 0 0 3px;
        font-size: $type-lg;
        &:after {
          content: "%";
        }
      }
    }
  }
}

.accuracy-negative-null {
  @extend .accuracy-item;
  border: 2px solid $color-gray-element;
  background: repeating-linear-gradient(45deg, transparent, transparent 6px, $color-gray-element 6px, $color-gray-element 12px);
  box-shadow: none;

  label {
    color: $color-white-medium;
  }
}

@for $i from 0 through 40 {
  $alpha: $i * 0.01;
  .accuracy-negative-#{$i} {
    @extend .accuracy-item;
  }
}

@for $i from 41 through 100 {
  $alpha: $i * 0.01;
  .accuracy-positive-#{$i} {
    @extend .accuracy-item;
    border: 2px solid rgba(0, 200, 159, $alpha);
    box-shadow: 0 0 36px 3px $color-green-note;
  }
}
