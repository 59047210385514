@use '../shared' as *;


.fullscreen-curtains-right-pane {
  i {
    position: relative;
    top: -30px;
    left: 30px;
  }

  .list {
    margin-top: 10px;
  }
}
