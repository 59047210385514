@use '../shared' as *;
@use '../components/general.scss' as *;

.versus {
  &-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    flex-direction: column;
  }

  &-box {
    font-size: $type-sm;
    font-weight: $default;

    &-left {
      @extend .versus-box;
      text-align: right;
      
      &-top {
        font-size: $type-sm;
        border: 1px solid $color-white-note;
        margin: 0 0 24px 0;
        background: $color-gray-light;
        height: 48px;
        padding: 12px;
        align-content: center;
      }
      
      &-bottom {
        font-size: $type-default;

        span {
          max-width: 100%;
          text-align: right
        }
      }
    }

    &-right {
      @extend .versus-box;
      
      &-top {
        font-size: $type-sm;
        border: 1px solid $color-white-note;
        margin: 0 0 24px 0;
        background: $color-gray-light;
        height: 48px;
        padding: 12px;
        align-content: center;
      }
      
      &-bottom {
        font-size: $type-default;

        span {
          max-width: 100%;
        }
      }
    }
  }
}


.box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 24px 0;
}

.box .inner {
  width: 100%;
  min-width: 100%;
  height: 48px;
  
  line-height: 48px;
  font-size: $type-lg;
  font-family: $font-default;
  font-weight: $bold;
  white-space: nowrap;
  overflow: hidden;
}

.box .inner:first-child {
  // background-color: $color-white-note;
  background: linear-gradient(to right,  transparent 0%, $color-white-note 100%);

  color: $color-white-light;
  transform-origin: right;
  transform: perspective(100px) rotateY(-15deg);
}

.box .inner:last-child {
  // background-color: $color-white-slight;
  background: linear-gradient(to left,  transparent 0%, $color-white-slight 100%);

  color: $color-white-default;
  transform-origin: left;
  transform: perspective(100px) rotateY(15deg);
}

.box .inner span {
  position: absolute;
  padding: 0 12px;
}

.box .inner:first-child span {
  animation: slideRight .36s ease-in forwards;
  right: 100%;
}

.box .inner:last-child span {
  animation: slideLeft .36s ease-in forwards;
  left: 100%;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    right: 100%;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

//ANIMATED VERSION 
// .box .inner span {
//   position: absolute;
//   animation: marquee 3s linear infinite;
// }

// .box .inner:first-child span {
//   animation-delay: 1.5s;
//   left: 100%;
// }


// .box .inner:last-child span {
//   left: -100%;
// }

// @keyframes marquee {
//   0% {
//     left: 100%;
//   }

//   100% {
//     left: -100%;
//   }
// }
