@use '../shared' as *;


.title {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  margin: 0;
  z-index: $z-nav;

  i {
    font-size: 2em;
    margin-right: 0.5em;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }
  }


  small {
    font-size: $type-tiny;
    font-weight: $default;
    text-transform: uppercase;
    display: flex;
    color: $color-white-default;
    margin: 0;
    margin-top: 3px;
    line-height: auto;
  }

  img {
    height: 36px;
    align-self: center;
    margin: 0 12px 0 0;
    transition: $transition-transform;
    border-radius: $radius-default;

  }

 

  #effect {
    font-size: $type-lg;
    padding: 0;
    margin: 0;
    font-weight: $default;
    text-transform: none;
    transition: $transition-transform;
    text-align: left;

    small {
      font-size: $type-tiny;
      font-size: $type-teeny;

    }
    

    span {
      color: $color-gray;
      text-transform: uppercase;

    }

  }
}
