@use '../shared' as *;

.pretty-number {
  font-size: $type-sm;
  
  @media (min-width: $break-md) {
     font-size: $type-md;

   }
  .prefix {
    font-size: $type-sm;
    
    @media (min-width: $break-md) {
       font-size: $type-md;

     }
    margin: 0 3px 0 0;
    color: $color-white-half;
  }
  .suffix {
    font-size: $type-sm;
    
    @media (min-width: $break-md) {
       font-size: $type-md;

     }
    margin: 0 0 0 3px;
    color: $color-white-half;
  }
}

.pretty-number-hero {
  @extend .pretty-number;
  font-size: $type-sm;
  
  @media (min-width: $break-md) {
    font-size: $type-md;

  }

  small {
    display: block;
    margin: 0 0 3px 0;
  }

  h1 {
    font-size: $type-lg;
    font-weight: $light;
    color: $color-white;

    @media (min-width: $break-md) {
      font-size: $type-xxl;
    }
  }
}
