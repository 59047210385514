@use '../shared' as *;

.feature-stat {
  // position: relative;

  .stat-container {
    display: flex;
    justify-content: space-between; 

    &-left {
      display: flex;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
    }
  


    .stat {
      display: flex;
      flex-direction: column;
      z-index: $z-base;
      align-items: flex-end;
      padding: 0;
      margin: 0;

      @media (min-width: $break-md) {
        display: flex;
        flex-direction: column;
        z-index: $z-base;
        border-radius: $radius-default;
        margin: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .number {
        display: flex;
        flex-direction: column;
        font-size: $type-md;
        color: #fff;
        font-weight: $default;

        @media (min-width: $break-md) {
          font-size: $type-lg;
        }

        .number-change {
          font-size: $type-sm;

          @media (min-width: $break-md) {
            font-size: $type-md;
          }
        }
      }

      small {
        font-size: $type-note;
        font-weight: $default;
        text-transform: uppercase;
        display: flex;
        color: $color-white-default;
        margin: 2px 0;
        line-height: auto;
      }

      &-hero {
        @extend .stat;
        background: transparent;
        margin: 0;
        padding: 0;
        position: absolute;
        display: flex;
        top: 60px;
        left: 24px;
        
        @media (min-width: $break-md) {
          top: 36px;
          left: 48px;
        }

      }

      &-left {
        @extend .stat;
      }

      &-right {
        @extend .stat;
        min-width: 72px;
        margin: 0 12px 0 0;
        

        &-bad {
          @extend .stat-right;
          background: none;
          @media (min-width: $break-md) {
            background: $color-pink-slight;
          }
        }
        &-good {
          @extend .stat-right;
          background: none;
          @media (min-width: $break-md) {
            background: $color-mid-slight;
          }
        }

        &-hero {
          @extend .stat-right;
          margin: 0 0 0 12px;
          background: $color-white-note;
          padding: 6px 24px;

          &-bad {
            @extend .stat-right-hero;
            background: none;
            @media (min-width: $break-md) {
              background: $color-pink-slight;
            }
          }
          &-good {
            @extend .stat-right-hero;
            background: none;
            @media (min-width: $break-md) {
              background: $color-mid-slight;
            }
          }
        }
      }
    }
  }
}
