
@use '../shared' as *;

.number-change {
  display: flex;

  .up {
    display: flex;
    color: $color-mid;
    align-items: flex-start;
    i {
      color: $color-mid;
      font-size: $type-default;
    }
  }
  .down {
    display: flex;
    color: $color-pink;
    align-items: flex-end;
    i {
      color: $color-pink;
      font-size: $type-default;
    }
  }
}
