@use '../shared' as *;

.meme-container {
  display: flex;
  color: $color-white;
  flex-direction: column;
  margin: 6px 0;
  padding: 12px;
  border-radius: $radius-default;
  transition: $transition-background;
  background: $color-gray-half;
  position: relative;
  border: 1px solid transparent;
  min-height: 400px;

  @media (min-width: $break-md) {
    margin: 12px;
    padding: 24px;
    background: $color-gray-light;

  }
}

.meme-data {
    padding: 24px;
  margin: 24px 0;


  h2 {
    span {
      color: $color-white-half;
    }

    font-size: $type-h2;
    padding: 12px 0 0 0;
    margin: 0;
    font-weight: $default;
  }

  #logo {
    transform-origin: center;
  }

  &:hover {
    #logo {
      transition: $transition-transform;
      transform: scale(1.6) translateX(-12px);
      transform-origin: center;
    }

    #effect {
      transition: $transition-transform;
      transform: translateX(-12px);
    }
  }

  &-meta {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 36% auto;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
  }

  &-list {
    margin: 12px 0;

    .list-module {
      margin: 0.5em 0 0 0;
    }
  }

  &-tags {
    display: flex;
    flex-direction: column;
    margin: 12px 0 0 0;
  }

  &-duo {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-end;
    margin: 12px 0;
  }

  &-ai {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
  }

  &-tri {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, auto);
    flex-direction: column;
    align-items: flex-end;
    margin: 12px 0;
  }

  &-split {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 36% auto;
    flex-direction: column;
    align-items: start;
    margin: 12px 0;

    h2 {
      span {
        color: $color-white-half;
      }

      font-size: $type-h2;
      padding: 6px 0 0 0;
      margin: 0;
      font-weight: $default;
    }

    h3 {
      font-size: $type-h3;
      padding: 6px 0 0 0;
      margin: 0;
      font-weight: $default;
    }
  }
}
