@use '../shared' as *;

.tags {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0;
  margin: 12px 0;
  justify-content: flex-start;

  a {
    text-decoration: underline;
    color: $color-brand;
    font-weight: $regular;
  }

  .tag {
    list-style: none;
    background: $color-gray-medium;
    padding: 6px 12px;
    margin: 0;
    font-size: $type-default;
    font-weight: $regular;
    flex-grow: 0;
    border-radius: $radius-slight;
    transition: $transition-scale;

    .tag-label {
      color: $color-white;
      text-align: left;
      padding-right: 4px;
    }

    .tag-value {
      color: $color-white;
      text-align: left;
      color: $color-white-half;
      font-weight: $bold;
    }

    &:hover {
      background: $color-gray-darkest;
      transform: scale(1.3);
      transition: $transition-scale;
    }
  }
}
