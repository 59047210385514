

$color-white: #fff;
$color-white-half: rgba(255, 255, 255, 0.5);
$color-white-medium: rgba(255, 255, 255, 0.75);
$color-white-default: rgba(255, 255, 255, 0.9);
$color-white-light: rgba(255, 255, 255, 0.3);
$color-white-slight: rgba(255, 255, 255, 0.1);
$color-white-note: rgba(255, 255, 255, 0.06);
$color-white-hint: rgba(255, 255, 255, 0.03);
$color-white-element: rgba(255, 255, 255, 0.02);

$color-red: rgba(220,52,52, 1); //#DC3434
$color-red-default: rgba(220,52,52, 0.9);
$color-red-medium: rgba(220,52,52, 0.75);
$color-red-half: rgba(220,52,52, 0.5);
$color-red-light: rgba(220,52,52, 0.3);
$color-red-slight: rgba(220,52,52, 0.1);
$color-red-note: rgba(220,52,52, 0.06);
$color-red-hint: rgba(220,52,52, 0.06);

$color-pink: rgba(244,89,89, 1); //#f45959
$color-pink-default: rgba(244,89,89, 0.9);
$color-pink-medium: rgba(244,89,89, 0.75);
$color-pink-half: rgba(244,89,89, 0.5);
$color-pink-light: rgba(244,89,89, 0.3);
$color-pink-slight: rgba(244,89,89, 0.1);
$color-pink-note: rgba(244,89,89, 0.06);
$color-pink-hint: rgba(244,89,89, 0.06);

$color-orange: rgba(255, 195, 42, 1); //#FFC32A
$color-orange-default: rgba(255, 195, 42, 0.9);
$color-orange-medium: rgba(255, 195, 42, 0.75);
$color-orange-half: rgba(255, 195, 42, 0.5);
$color-orange-light: rgba(255, 195, 42, 0.3);
$color-orange-slight: rgba(255, 195, 42, 0.15);
$color-orange-note: rgba(255, 195, 42, 0.06);
$color-orange-hint: rgba(255, 195, 42, 0.06);

$color-yellow: rgba(249, 248, 113, 1); //f9f871
$color-yellow-default: rgba(249, 248, 113, 0.85);
$color-yellow-medium: rgba(249, 248, 113, 0.75);
$color-yellow-half: rgba(131, 131, 44, 0.5);
$color-yellow-light: rgba(249, 248, 113, 0.3);
$color-yellow-slight: rgba(249, 248, 113, 0.15);
$color-yellow-note: rgba(249, 248, 113, 0.06);
$color-yellow-solid: rgba(249, 248, 113, 1);

$color-mid: rgba(0,166,178, 1); //#00A6B2
$color-mid-default: rgba(0,166,178, 0.9);
$color-mid-medium: rgba(0,166,178, 0.75);
$color-mid-half: rgba(0,166,178, 0.5);
$color-mid-light: rgba(0,166,178, 0.3);
$color-mid-slight: rgba(0,166,178, 0.15);
$color-mid-note: rgba(0,166,178, 0.06);
$color-mid-hint: rgba(0,166,178, 0.01);

$color-green: rgba(0,200,159, 1); //#00C89F
$color-green-default: rgba(0,200,159, 0.85);
$color-green-medium: rgba(0,200,159, 0.75);
$color-green-half: rgba(0,200,159, 0.5);
$color-green-light: rgba(0,200,159, 0.3);
$color-green-slight: rgba(0,200,159, 0.15);
$color-green-note: rgba(0,200,159, 0.06);
$color-green-hint: rgba(0,200,159, 0.01);

$color-indigo: rgba(150, 143, 205, 1); //#968fcd
$color-indigo-default: rgba(150, 143, 205, 0.84);
$color-indigo-medium: rgba(150, 143, 205, 0.72);
$color-indigo-half: rgba(150, 143, 205, 0.48);
$color-indigo-light: rgba(150, 143, 205, 0.24);
$color-indigo-slight: rgba(150, 143, 205, 0.12);
$color-indigo-note: rgba(150, 143, 205, 0.06);
$color-indigo-hint: rgba(150, 143, 205, 0.01);

$color-brand: rgba(80, 184, 231, 1); //#50b8e7
$color-brand-default: rgba(80, 184, 231, 0.9);
$color-brand-medium: rgba(80, 184, 231, 0.75);
$color-brand-half: rgba(80, 184, 231, 0.5);
$color-brand-light: rgba(80, 184, 231, 0.3);
$color-brand-slight: rgba(80, 184, 231, 0.12);
$color-brand-note: rgba(80, 184, 231, 0.06);
$color-brand-hint: rgba(80, 184, 231, 0.03);

$color-default: rgba(0,40, 79, 1); //#00284F
$color-default-medium: rgba(0,40, 79, 0.75);
$color-default-half: rgba(0,40, 79, 0.5);
$color-default-light: rgba(0,40, 79, 0.3);
$color-default-slight: rgba(0,40, 79, 0.15);
$color-default-note: rgba(0,40, 79, 0.06);
$color-default-hint: rgba(0,40, 79, 0.03);
$color-default-hover: rgba(0,40, 79, 0.1);

$color-blue: rgba(19,90,144, 1); //#135A90
$color-blue-default: rgba(19,90,144, 0.85);
$color-blue-medium: rgba(19,90,144, 0.75);
$color-blue-half: rgba(19,90,144, 0.5);
$color-blue-light: rgba(19,90,144, 0.3);
$color-blue-slight: rgba(19,90,144, 0.15);
$color-blue-note: rgba(19,90,144, 0.06);
$color-blue-hint: rgba(19,90,144, 0.03);
$color-blue-hover: rgba(19,90,144, 0.1);

$color-gray: #99aebb;
$color-gray-hint: rgba(0,13,33, 0.02);
$color-gray-note: rgba(0,13,33, 0.06);
$color-gray-slight: rgba(0,13,33, 0.15);
$color-gray-light: rgba(0,13,33, 0.36);
$color-gray-light-opaque: rgba(32, 43, 58, 1);
$color-gray-half: rgba(0,13,33, 0.48);
$color-gray-medium: rgba(0,13,33, 0.60);
$color-gray-dark: rgba(0,13,33,.75);
$color-gray-darker: rgba(0,13,33,.85);
$color-gray-default: rgba(0,13,33,.92);
$color-gray-darkest: #000d21;//alt 000d21
$color-gray-element: #1d2738;


$color-nav: $color-gray-default;
$color-backdrop: rgba(0, 13, 33, 0.48);

$color-black: rgba(0, 0, 0, 1);
$color-black-half: rgba(0, 0, 0, 0.5);
$color-black-hint: rgba(0, 0, 0, 0.1);

$color-background: #000d21;
$color-background-alt: #00284f;
$color-background-alt2: #00182f;
$color-background-alt3: #00203f;
$color-background-alt4: #002447;

$color-indicator: $color-orange-default;

$color-crosshairs: $color-brand;
$color-crosshairs-note: $color-brand-note;

$color-good: $color-mid;
$color-good-glow: $color-mid-light;

$color-bad: $color-pink;
$color-bad-glow: $color-pink-light;

$color-neutral: #4b6776;
$color-neutral-glow: rgba(104, 138, 151,.3);

