@use '../shared' as *;


.spark {

  svg {
    rect {
      fill: $color-brand-default !important;
      // stroke: $color-brand;
      // stroke-width: 1px;
    }
  }

  &-overlays {
    position: relative;
    width: 100%;
    top: 0;
    padding: 24px 0;
    margin: 0 auto;
    
    svg {
      position: absolute;
      top: 12px;
    }
  }
  
}
