@use '../shared' as *;

html {
  padding: 0;
  margin: 0;
  background-size: 6px 6px;
  overflow-x: hidden;
}

body {
  font-family: $font-default;
  font-size: $type-default;
  font-weight: $regular;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0 auto;
  height: 100%;
  color: $color-white;
  background: $color-gray-darkest;
  // REMOVE THE FOLLOWING TWO LINES TO ENABLE BACKGROUND COLOR SLIDER.
  // background: radial-gradient(square, $color-gray-darkest 0%, #00284F 100%);
  // background: linear-gradient(to bottom, $color-gray-darkest 0%, #00284f 100%);
  // background-attachment: fixed;

  background: linear-gradient(to bottom,  $color-background-alt3 60%, $color-gray-darkest 100%);
  background-attachment: fixed;
}


.dot-matrix {
  font-family: Arial, sans-serif;
  color: #fff;
  background: #111;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;

  &-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &-label {
    flex: 1;
    font-size: 14px;
  }

  &-dots {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    flex: 2;
  }

  &-dot {
    width: 8px;
    height: 8px;
    background-color: #0f0;
    border-radius: 50%;
  }

  &-value {
    flex: 1;
    text-align: right;
    font-size: 14px;
  }
}

.exit {
  background: transparent;
  color: $color-white;
  font-size: $type-lg;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    &:after {
      color: $color-white;
      font-size: $type-teeny;
      content: 'ESC';
      font-family: $font-default;
      border-radius: $radius-default;
      font-weight: $bold;
      padding: 3px 6px;
      margin: 0 0 0 12px;
      background: $color-white-light;
      position: absolute;
      right: 48px;
    }
  }
}

.expand {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  font-size: $type-sm;
  &:hover {
    color: $color-brand;
  }
}

.list-container {
  overflow-y: auto;
  max-height: 100%;
}

.capsule {
  padding:  6px 12px;
  border-radius: $radius-slight;
  font-size: $type-xxs;
  font-weight: $regular;
  // text-transform: uppercase;
  font-family: $font-default;
  margin: 12px 0 0 0;
  // display: inline-block;
  text-decoration: none;
  transition: $transition-padding;
  color: $color-white;
  background: $color-white-hint;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: fit-content;
  
  small {
    font-weight: $regular;
    color: $color-white;
    font-size: $type-tiny;
  }
  &:hover {
    background: $color-white-note;
    color: $color-white;
    // padding: 6px 16px;
    // transform: scale(1.2);
    // transition: $transition-padding;
    
  }
  
  img {
    height: 24px;
  }
  
  small {
    font-weight: $regular;
    font-size: $type-note;
    color: $color-white-medium;
    padding: 0;
    margin: 0;
    display: block;
  }
}



.masonry {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  padding: 12px;
  margin: 0 auto;
  max-width: 1600px;
  
  /* OVERRIDE MASONRY GRID GAP: Target both row and column gaps */
  div[style*='display: flex'][style*='flex-direction: row'],
  div[style*='display: flex'][style*='flex-direction: column'] {
    gap: 24px !important;
  }
  
  @media (min-width: $break-md) {
    top: 120px;
    padding: 12px 24px;
  }

}

.up {
  color: $color-mid;
  i {
    color: $color-mid;
    vertical-align: super;
    font-size: $type-teeny;
  }
}
.down {
  color: $color-pink;
  i {
    color: $color-pink;
    vertical-align: super;
    font-size: $type-teeny;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $default;
}

label {
  font-size: $type-teeny;
  color: $color-white-default;
  margin: 0 0 3px 0;
  text-transform: uppercase;
  display: flex;
}

h1 {
  font-size: $type-h1;
  padding: 0;
  margin: 0;

  span {
    color: $color-white-half;
  }
}

h5 {
  font-size: $type-default;
  font-weight: $bold;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $color-white-slight;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: $radius-default;
  background: $color-blue-medium;

  &:hover {
    background: $color-blue-default;
  }
}

.MuiDrawer-paper {
  padding: 0 !important;
  width: 320px;
  padding: 24px !important;
  background: $color-gray-darkest !important;
  // border-left: 2px solid $color-brand-slight !important;
  box-shadow: none;
  color: $color-white !important;
  text-transform: uppercase;
  z-index: $z-ultimate !important;
}

.MuiBackdrop-root {
  // background: transparent !important;
  background: $color-backdrop !important;
  z-index: $z-penultimate !important;
}

.MuiDrawer-root {
  position: unset !important;
  z-index: $z-penultimate !important;
}

#expand .MuiDrawer-paper {
  // margin-top: 72px;
  padding: 0 !important;
  width: 72%;
  padding: 24px !important;
  background: $color-gray-darkest !important;
  border-top: 1px solid $color-brand-slight !important;
  box-shadow: none;
  color: $color-white !important;
  z-index: $z-ultimate !important;
  box-sizing: border-box !important;
}

#expand-side .MuiDrawer-paper {
  // margin-top: 72px;
  padding: 0 !important;
  width: 28%;
  padding: 24px !important;
  background: $color-gray-darkest !important;
  // border-top: 1px solid $color-brand-slight !important;
  // border-left: 1px solid $color-brand-half !important;
  box-shadow: none;
  color: $color-white !important;
  z-index: $z-ultimate !important;
  box-sizing: border-box !important;
}

.premium {
  font-size: $type-huge;
  background: -moz-linear-gradient(top, $color-brand 36%, $color-blue 100%);
  background: -webkit-linear-gradient(top, $color-brand 36%, $color-blue 100%);
  background: radial-gradient(at top left, $color-brand 36%, $color-blue 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.beast {
  font-size: $type-huge;
  background: -moz-linear-gradient(top, $color-yellow 0%, $color-orange 72%, $color-pink 100%);
  background: -webkit-linear-gradient(top, $color-yellow 0%, $color-orange 72%, $color-pink 100%);
  background: radial-gradient(at top left, $color-yellow 0%, $color-orange 72%, $color-pink 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tooltip {
  background-color: $color-white-default !important;
  max-width: 200px !important;
  padding: 6px 12px !important;
  line-height: $type-xxs !important;
  font-size: $type-default !important;
  text-align: center !important;
  border-radius: $radius-slight !important;
  color: $color-gray-darkest !important;
  font-weight: $regular !important;
  text-transform: uppercase !important;
  font-family: $font-default !important;
  margin: 0 0 12px 0 !important;
  cursor: pointer;

  &-dev {
    font-size: $type-tiny;
    button {
      font-size: $type-tiny;
      outline: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      color: $color-white;
      background: transparent;
      cursor: pointer;

      &:hover {
        i {
          color: $color-yellow;
        }
      }
    }
  }
}

.tooltip-arrow {
  &::before {
    color: $color-white-default !important;
  }
}

.toggle {
  &-container {
    display: flex;
    flex-direction: row;
    min-height: 36px;
    height: 36px;
    align-items: center;
    font-size: $type-default;
    padding: 0;
    background: transparent;
  }

  &-button {
    display: flex;
    align-items: center;
    color: $color-white;
    padding: 6px 24px;
    border-radius: $radius-slight;
    display: flex;
    justify-content: center;
    font-family: $font-default;
    text-transform: uppercase;
    font-size: $type-default;
    font-weight: $regular;
    cursor: pointer;
    outline: 2px solid transparent;
    background: transparent !important;
    border: none;
    margin: 0 6px 0 0;

    &:hover {
    }

    i {
      align-self: center;
      position: absolute;
      margin: 42px 0 0 0;
      font-size: $type-default;
      color: $color-brand;
    }

    &-active {
      @extend .toggle-button;
      background: transparent !important;
      color: $color-white;
    }
    &-inactive {
      @extend .toggle-button;
      background: transparent !important;
      color: $color-white-half;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 84px;
  left: 12px;
  right: 12px;

  @media (min-width: $break-md) {
    display: grid;
    grid-template-columns: 70% auto;
  }
  &-expanded {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 84px;
    left: 12px;
    right: 12px;
  }
}

.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.pt1 {
  padding-top: 1em;
}

.mobile {
  display: none;
  @media (max-width: $break-md) {
    display: block;
  }
}

.desktop {
  display: none;
  @media (min-width: $break-md) {
    display: block;
  }
}
